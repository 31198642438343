import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import CoffeeLoading from "react-loadingg/lib/CoffeeLoading";

import styles from "assets/jss/material-kit-react/components/loaderStyle.js";
import { primaryColor } from "assets/jss/material-kit-react/components/loaderStyle.js";

const useStyles = makeStyles(styles);

const Loader = ({ size }) => {
  const classes = useStyles();

  return (
    <div className={classes.loader}>
      <CoffeeLoading size={size} speed={1} color={primaryColor} />
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.oneOf(["small", "default", "large"])
};

Loader.defaultProps = {
  size: "default"
};

export default Loader;
