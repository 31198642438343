import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "fi",
    debug: process.env.NODE_ENV === "development" ? true : false,
    backend: {
      backends: [
        LocalStorageBackend, // Primary, local storage cache
        HttpBackend, // Secondary, http fetch from server
      ],
      backendOptions: [
        {
          prefix: "i18next_cache_",
          expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
          defaultVersion: "v1.0.3", // Update when texts change
        },
        {
          loadPath: "/locales/{{lng}}/{{ns}}.json",
          allowMultiLoading: false,
        },
      ],
    },
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false, // React is already safe from XSS
    },
  });

export default i18n;
