import { grayColor } from "assets/jss/material-kit-react.js";

const errorPageStyle = {
  container: {
    margin: "auto",
    textAlign: "center",
    color: grayColor,
    position: "absolute",
    inset: "0px"
  },
  innerContainer: {
    margin: "auto",
    position: "absolute",
    top: "50%",
    width: "100%",
    padding: "30px",
    "-ms-transform": "translateY(-50%)",
    transform: "translateY(-50%)"
  },
  warningIcon: {
    fontSize: "80px"
  }
};

export default errorPageStyle;
