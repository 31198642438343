import { primaryColor } from "assets/jss/material-kit-react.js";

export { primaryColor };

const loaderStyle = {
  loader: {
    margin: "auto",
    textAlign: "center",
    padding: "30px"
  }
};

export default loaderStyle;
