import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material UI Components
import Typography from "@material-ui/core/Typography";

// Material UI Icons
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

// Styles
import styles from "assets/jss/material-kit-react/views/errorPage.js";
const useStyles = makeStyles(styles);

const ErrorView = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <WarningRoundedIcon className={classes.warningIcon} />
        <Typography variant="h2">{"Error incoming!"}</Typography>
        <Typography variant="h5">
          {
            "Take chill, drink a beer and try again :) You can also inform us by mailing to kapteenisto(at)nmksv.org"
          }
        </Typography>
      </div>
    </div>
  );
};

export default ErrorView;
