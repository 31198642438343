import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider, createTheme } from "@material-ui/core";

import "assets/scss/material-kit-react.scss?v=1.8.0";

import Loader from "components/Loader/Loader";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";

import { SnackbarProvider } from "notistack";
import Button from "@material-ui/core/Button";

// Translations
import "./i18n";

// pages for this product
const ApplicationDetail = lazy(() =>
  import("views/Application/ApplicationDetail.js")
);
const ApplicationList = lazy(() =>
  import("views/Application/ApplicationsList.js")
);
const LandingPage = lazy(() => import("views/LandingPage/LandingPage.js"));
const History = lazy(() => import("views/History/History.js"));
const InterestedPage = lazy(() =>
  import("views/InterestedPage/InterestedPage.js")
);
const ContactPage = lazy(() => import("views/ContactPage/ContactPage.js"));
const Application = lazy(() => import("views/Application/Application.js"));
const ApplicationRequestList = lazy(() =>
  import("views/Application/ApplicationRequestList.js")
);
const ApplicationRequestDetails = lazy(() =>
  import("views/Application/ApplicationRequestDetails.js")
);
const AdminPanel = lazy(() => import("views/AdminPanel/AdminPanel.js"));
const ApplyTimesView = lazy(() =>
  import("views/Application/ApplyTimesView.js")
);
const FeedbackList = lazy(() => import("views/Feedback/FeedbackList.js"));
const FeedbackDetail = lazy(() => import("views/Feedback/FeedbackDetail.js"));
const DBAdmin = lazy(() => import("views/DatabaseAdmin/DatabaseAdmin.js"));
const RestoreAdminPage = lazy(() =>
  import("views/RestoreAdminPage/RestoreAdminPage.js")
);
const DeletedFeedback = lazy(() =>
  import("views/RestoreAdminPage/DeletedFeedbackDetails.js")
);
const DeletedRequest = lazy(() =>
  import("views/RestoreAdminPage/DeletedRequestDetails.js")
);
const DeletedApplication = lazy(() =>
  import("views/RestoreAdminPage/DeletedApplicationDetails.js")
);

var hist = createBrowserHistory();

const ProtectedRoute = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(component)} {...args} />
);

const onRedirectCallback = (appState) => {
  hist.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : (window.location.href = "admin")
  );
};

const theme = createTheme({});

// Reference for notifications
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH_DOMAIN}
    clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={process.env.REACT_APP_AUTH_AUDIENCE}
    useRefreshTokens={true}
    cacheLocation="localstorage"
    scope="
    openid
    read:applications
    delete:applications
    read:applicationrequests
    update:applicationrequests
    delete:applicationrequests
    update:applyopen
    read:feedback
    delete:feedback
    all:database"
  >
    <Router history={hist}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          ref={notistackRef}
          action={(key) => <Button onClick={onClickDismiss(key)}>OK</Button>}
          variant="success"
        >
          <ErrorBoundary>
            <Suspense
              fallback={
                <Loader size={window.innerWidth <= 600 ? "default" : "large"} />
              }
            >
              <Switch>
                <ProtectedRoute
                  path="/admin/deleted/feedback/:id"
                  component={DeletedFeedback}
                />
                <ProtectedRoute
                  path="/admin/deleted/request/:id"
                  component={DeletedRequest}
                />
                <ProtectedRoute
                  path="/admin/deleted/application/:id"
                  component={DeletedApplication}
                />
                <ProtectedRoute
                  path="/admin/applicationrequests/:id"
                  component={ApplicationRequestDetails}
                />
                <ProtectedRoute
                  path="/admin/applicationrequests"
                  component={ApplicationRequestList}
                />
                <ProtectedRoute
                  path="/admin/applications/:id"
                  component={ApplicationDetail}
                />
                <ProtectedRoute
                  path="/admin/applications"
                  component={ApplicationList}
                />
                <ProtectedRoute
                  path="/admin/feedbacks/:id"
                  component={FeedbackDetail}
                />
                <ProtectedRoute
                  path="/admin/feedbacks"
                  component={FeedbackList}
                />
                <ProtectedRoute
                  path="/admin/applytimes"
                  component={ApplyTimesView}
                />
                <ProtectedRoute path="/admin/database" component={DBAdmin} />
                <ProtectedRoute
                  path="/admin/restore"
                  component={RestoreAdminPage}
                />
                <ProtectedRoute path="/admin" component={AdminPanel} />
                <Route path="/history" component={History} />
                <Route path="/contact" component={ContactPage} />
                <Route path="/interested" component={InterestedPage} />
                <Route path="/application" component={Application} />
                <Route path="/palaute">
                  <Redirect to="/contact#palaute" />
                </Route>
                <Route path="/feedback">
                  <Redirect to="/contact#palaute" />
                </Route>
                <Route
                  path="/careers"
                  component={() => {
                    window.location.href =
                      "https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim";
                    return null;
                  }}
                />
                <Route
                  path="/ennen"
                  component={() => {
                    window.location.href =
                      "https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim";
                    return null;
                  }}
                />
                <Route path="/" component={LandingPage} />
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  </Auth0Provider>,
  document.getElementById("root")
);
